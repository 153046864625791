
.mypage.common_container {background: #f8f9fa;}
.mypage .bx_tit{margin-bottom:37px}

/* 마이페이지 - new quick menu(텍스트화) */
.mypage .quick_menu_wrap{height:135px;margin-bottom:10px;border:1px solid #242428;border-radius:2px;background:#fff;box-sizing:border-box}
.mypage .quick_menu_wrap:after{clear:both;display:block;content:''}
.mypage .quick_menu_wrap .title{float:left;width:257px;height:100%;background:#242428;text-align:center;padding-top:48px;box-sizing:border-box}
.mypage .quick_menu_wrap .title .title_txt{overflow:hidden;font-size: 26px;line-height: 38px;letter-spacing: -1px;font-weight: 700;color: #fff;}
.mypage .quick_menu_wrap .quick_menu_list{float:left;max-width:860px;margin-top:44px;padding-left:30px;box-sizing:border-box}
.mypage .quick_menu_wrap .quick_menu_list:after{clear:both;display:block;content:''}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item{float:left;width:206px;border-left:1px dotted #cfd0d7;text-align:center;box-sizing:border-box;}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item:first-child{border-left:0;margin-left: 0;}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item .quick_link{display:block;font-size:0}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item .quick_link:hover .qmenu_txt{text-decoration:underline}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item .quick_link:before{display:inline-block;vertical-align:middle;content:''}
.mypage .quick_menu_wrap .quick_menu_list .qmemu_box{display:inline-block;margin-left:10px;padding-top:2px;vertical-align:middle;}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item .qmenu_tit{display:block;font-size:12px;line-height:1;font-weight:700;letter-spacing:-0.5px}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item .qmenu_txt{display:block;margin-top:5px;line-height:1;font-weight:700;color:#fa2828;}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item .qmenu_txt .number{font-size:20px;font-weight:700}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item .qmenu_txt .txt{font-size:16px;letter-spacing:-0.5px;line-height:20px}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item .qmenu_txt .txt:after{display:inline-block;font-size: 0;content:'';vertical-align: middle;margin-top: -2px;}
.mypage .quick_menu_wrap .quick_menu_list .item_payco .quick_link:before {
    @include useSvg-old('mypagepoint', 46px);
}
.mypage .quick_menu_wrap .quick_menu_list .item_reserve .quick_link:before {
    @include useSvg-old('myticket', 46px);
}
.mypage .quick_menu_wrap .quick_menu_list .item_coupon .quick_link:before {
    @include useSvg-old('mypageticket', 46px);
}
.mypage .quick_menu_wrap .quick_menu_list .item_info .quick_link:before {
    @include useSvg-old('membership', 46px);
}

/* mypage tab */
.mypage_tab{display: table;table-layout: fixed;width: 100%;margin-bottom:30px;}
.mypage_tab li{display: table-cell;height: 48px;line-height: 48px;border: 1px solid #ecedf2;box-sizing: border-box;width: 50%;border-left: 0px;}
.mypage_tab li.on{background: #242428;border-color: #242428;}
.mypage_tab li.on a{color: #fff;}
.mypage_tab li:first-of-type{border-left: 1px solid #ecedf2;}
.mypage_tab li a{display: block;text-align: center;font-weight: 500;}
.myreserve{overflow:hidden;margin-bottom:30px}
.myreserve:after{display:block;clear:both;content:''}

/* 마이페이지 > 취소표대기 신청내역*/
.sort_area:after{display:block;clear:both;content:''}
.tab_menu_wrap:after{clear:both;display:block;content:''}
.tab_menu_wrap .tab_menu{float:left}
.tab_menu_wrap .tab_menu:after{clear:both;display:block;content:''}
.tab_menu_wrap .tab_menu .tab_item{float:left}
.tab_menu_wrap .tab_menu .tab_item .tab_link{font-size:13px;color:#878d95;letter-spacing:-0.5px}
.tab_menu_wrap .tab_menu .tab_item.on .tab_link{font-weight:bold;color:#242428}
.tab_menu_wrap .tab_menu .tab_item .tab_link:before{display:inline-block;width:1px;height:13px;margin:0 6px -1px;background:#ecedf2;content:''}
.tab_menu_wrap .tab_menu .tab_item:first-child .tab_link:before{display:none}
.help_rcont .h3_line .wait_guide{position:absolute;top:0;right:0;min-width:163px;padding:4px 11px;border-color:#f56c36;font-size:13px;font-weight:400;line-height:18px;color:#f56c36}
.help_rcont .h3_line .wait_guide:before{display:inline-block;margin:1px 3px 0 0;vertical-align:top;@include useSvg-old('info_orange', 16px);content:''}

.ticket_box{letter-spacing:-0.42px}
.ticket_box .ticket_item{position:relative;min-height:189px;margin-top:8px;padding:15px 124px 15px 130px;border:1px solid #ecedf2;box-sizing:border-box}
.ticket_box .ticket_item:before{position:absolute;top:0;left:130px;width:1px;height:100%;background-color:#e6e6e6;content:''}
.ticket_box .ticket_item:first-of-type{margin:0}
.ticket_box .ticket_item .thumb_box{overflow:hidden;position:absolute;top:50%;left:0;-webkit-transform:translateY(-50%);transform:translateY(-50%);width:130px;height:182px}
.ticket_box .ticket_item .thumb_box.type_poster img{display:block;width:100%;height:100%}
.ticket_box .ticket_item .thumb_box.type_emblem_1{font-size:0}
.ticket_box .ticket_item .thumb_box.type_emblem_1:before{display:inline-block;height:100%;vertical-align:middle;content:''}
.ticket_box .ticket_item .thumb_box.type_emblem_1 img{display:inline-block;width:110px;margin:0 10px;vertical-align:middle;font-size:1px}

.ticket_box .ticket_item .thumb_box.type_emblem_2:before{display:inline-block;height:100%;vertical-align:middle;content:''}
.ticket_box .ticket_item .thumb_box.type_emblem_2 img{position:absolute;width:74px;height:74px}
.ticket_box .ticket_item .thumb_box.type_emblem_2 .emblem_1{top:9px;left:7px}
.ticket_box .ticket_item .thumb_box.type_emblem_2 .emblem_2{bottom:9px;right:7px}
.ticket_box .ticket_item .thumb_box.type_emblem_2 .vs{overflow:hidden;position:absolute;top:50%;left:50%;font-size:14px;font-weight:700;color:#a7acb6;transform:translate(-50%, -50%);cursor:default}

.ticket_box .ticket_item .info_box{padding-left:20px}
.ticket_box .ticket_item .prdt_info{min-height:76px}
.ticket_box .ticket_item .league_tit{overflow:hidden;max-height:34px;margin-bottom:4px;padding-right:65px;font-size:13px;line-height:17px}
.ticket_box .ticket_item .prdt_tit{overflow:hidden;max-height:38px;margin-bottom:2px;padding-right:100px;font-size:16px;font-weight:bold;line-height:19px}
.ticket_box .ticket_item .date{font-size:14px;font-weight:bold;line-height:21px}
.ticket_box .ticket_item .ticket_info{font-size:13px;font-weight: 400;line-height: 20px; color:#878d95;}

.ticket_box .ticket_item .btn{position:absolute;top:24px;right:25px;width:100px;padding:4px 11px;font-size:13px;line-height:18px;text-align:center}
.ticket_box .ticket_item .btn.btn_wait{border-color:#ff8700;background:#ff8700;color:#fff}
.ticket_box .ticket_item .btn.btn_wait:hover{border-color:#d47000;background:#d47000}
.ticket_box .txt_orange{color:#f56c36}
.ticket_box .common_data_none{margin-top:140px}
.ticket_box .common_data_none .text{font-size:14px;line-height:18px}



.mypage_coupon .tab_menu_wrap .btn_blank:after,
.mypage_coupon .tab_menu_wrap .coupon_short_cut:after {
    @include useSvg-old('arrow', 16px);
}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item .qmenu_txt .txt:after{
    @include useSvg-old('arrow_red', 16px);
}
.mypage_trip .tbl_desc_area .tbl_desc_item.notice:before{
  @include useSvg-old('info', 18px);
}
.mypage_coupon .condition_box .ly_coupon_condition .btn_close::before {
  @include useSvg-old('close', 16px);
}