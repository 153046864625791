/* 티켓오픈공지 */
.search_cont {
  .search_notice {
    padding: 10px 30px 0;
    border: 1px solid #ecedf2;
    border-radius: 2px;
    background: #fff;
    &_title {
      padding: 16px 20px 8px;
      color: #242428;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }
    &_list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &_item {
      position: relative;
      flex: 0 0 auto;
      padding: 20px 0 30px;
      width: 520px;
      border-bottom: 1px dotted #cfd0d7;
      &:nth-last-child(-n + 2):nth-child(odd) {
        border: 0;
      }
      &:last-child {
        border: 0;
      }
    }
    &_link {
      display: flex;
      gap: 0 17px;
      .imgbox {
        flex: 0 0 auto;
        width: 110px;
        height: 134px;
        >img {
          width: 100%;
        }
      }
      .info {
        overflow: hidden;
        flex: 1;
        padding: 10px 0;
        .title {
          display: flex;
          min-height: 40px;
          align-items: center;
          margin-bottom: 24px;
          font-weight: 700;
          color: #242428;
          font-size: 16px;
          line-height: 20px;
          .ellipsis {
            @include multi-ellipsis(2);
          }
        }
        .text {
          @include ellipsis;
          padding-right: 80px;
          color: #62676C;
          font-size: 13px;
          line-height: 21px;
          &>strong {
            display: inline-block;
            min-width: 50px;
            vertical-align: top;
          }
          &>em {
            font-weight: 700;
            color: #FA2828;
          }
        }
      }
    }
  }
}